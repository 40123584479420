<template>
  <v-app id="fnake-app">
    <GameScreen v-if="gameScreenShown" />
    <StartMenu v-else @startGame="showGameScreen()" />
    <img class="clarkson" src="~@/assets/fnake/mini_clarkson.jpg" v-for="clarkson in clarksons"
      :key="clarkson.y + ':' + clarkson.x" :style="clarksonStyle(clarkson)" />
  </v-app>
</template>

<style lang="scss">
@font-face {
  font-family: "Atari";
  src: url("~@/assets/fnake/fonts/AtariST8x16SystemFont.ttf");
}

#fnake-app {
  font-family: "Atari", monospace;
  text-align: center;
  background: #020202;
  color: white;
  margin: -80px 0;
}

.clarkson {
  image-rendering: pixelated;
  position: fixed;
}
</style>

<script>
/* eslint-disable */
import StartMenu from "./views/FnakeLeaderboard.vue";
import GameScreen from "./views/FnakeGame.vue";
const clarksonSize = 100;
export default {
  name: "FnakeGame",
  components: {
    StartMenu,
    GameScreen,
  },
  data: function () {
    return {
      clarksons: [],
      gameScreenShown: false,
      gameStarted: false,
    };
  },
  methods: {
    showClarksonTransition: function () {
      for (var i = 0; i < window.innerWidth / clarksonSize; i++) {
        for (var j = 0; j < window.innerHeight / clarksonSize; j++) {
          const clarkPos = { x: i, y: j };
          setTimeout(() => {
            this.clarksons.push(clarkPos);
          }, Math.random() * 500);
        }
      }
      setTimeout(() => {
        for (var i = 0; i < this.clarksons.length; i++) {
          setTimeout(() => {
            this.clarksons.splice(0, 1);
          }, (i * 500) / this.clarksons.length);
        }
      }, 1000);
    },
    showGameScreen: function () {
      this.showClarksonTransition();
      setTimeout(() => {
        this.gameScreenShown = true;
      }, 550);
    },
    clarksonStyle: function (clarkson) {
      return {
        top: clarkson.y * clarksonSize + "px",
        left: clarkson.x * clarksonSize + "px",
        height: clarksonSize + "px",
        width: clarksonSize + "px",
      };
    },
  },
};
</script>
