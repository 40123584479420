<template>
  <v-container fill-height fluid>
    <v-layout row wrap align-center>
      <v-flex>
        <div class="menu-title">
          Tupplista
        </div>
        <table v-if="!networkFailed">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Poägg</th>
              <th>Namn</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(score, index, id) in hiScores" :key="id" :style="colors[index]">
              <td>{{ index + 1 }}</td>
              <td>{{ score.score }}</td>
              <td>{{ score.name }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="networkFailed">
          De gick inte å läsa in tupplista
          <br>
          <v-btn id="fnake-button" @click="$emit('startGame')">Käka ägg ändå</v-btn>
        </div>
        <div v-else>
          <v-btn id="fnake-button" @click="$emit('startGame')">Käka ägg</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'StartMenu',
  data: function () {
    return {
      networkFailed: false,
      hiScores: [],
      colors: [
        'color: #F4012A',
        'color: #01f419',
        'color: #2101f4',
        'color: #f401e8',
        'color: #01f4e8',
        'color: #ff8e21',
        'color: #fd51a4',
        'color: #a3f401',
        'color: #a304f1',
        'color: #f3f401'
      ]
    }
  },
  methods: {
    fetchLeaderboard () {
      const baseURL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000'
      axios
        .get(`${baseURL}/api/fnake/leaderboard`)
        .then(resp => {
          for (const score of resp.data) {
            this.hiScores.push(score)
          }
        })
        .catch(() => {
          this.networkFailed = true
        })
    }
  },
  mounted () {
    this.fetchLeaderboard()
  }
}
</script>

<style>
@font-face {
  font-family: "Atari";
  src: url("~@/assets/fnake/fonts/AtariST8x16SystemFont.ttf");
}

.menu-title {
  font-family: "Atari", monospace;
  font-size: 32px;
  margin-bottom: 24px;
}

body #fnake-button {
  background-color: #ffeb3b;
  color: #121212;
  border-radius: 0;
  margin-top: 40px;
  font-size: 24px;
}

.v-ripple__container {
  display: none;
}

table {
  margin: auto;
  font-size: 20px;
}

th {
  font-weight: bold;
  color: #fefe00;
}

th,
td {
  padding: 4px 8px;
  text-align: left;
  min-width: 100px;
}
</style>
